//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";
import { DashboardNav } from "../DashboardComponents/DashboardNav";
import { StudentDashboard } from "../DashboardComponents/StudentDashboard";
import { ConfirmationPopup } from "../DashboardComponents/ConfirmationPopup";

const StudentPage = ({ }) => {

    document.title = 'Student Overview';

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");


    
    

    return (
        <Fragment>
            <div className='main-container page max-height-screen'>

                <DashboardNav />
                <StudentDashboard/>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(StudentPage);
export { connection as StudentPage };