//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import placeholder from '../../Images/placeholder.jpg'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import incompletePopsicle from '../../Images/incomplete_popsicle.png'
import progressBar from '../../Images/progress_bar.svg'

const StudentDashboard = ({ }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");

    const [content, setContent] = useState(null);
    const [quests, setQuests] = useState(null);

    useEffect(() =>
        userActions.GetClassroomContent(classroom_id, token, (data) => {
            setContent(data);
            console.log(data)
        }, '?student='+student_id), 
    []);

    return (
        <Fragment>
            <Container fluid className={'singleStudentView'}>
                <Row className='student-view-header'>
                    <Col sm={4} className='class-name'>
                        <h2>{content?.classroom.name}</h2>
                    </Col>

                    <Col sm={6} className='student-name'>
                        <h1>Student Dashboard</h1>
                    </Col>
                    <Col sm={2} className='quest-dashboard'>
                        <h3>Quest Dashboard</h3>
                    </Col>
                </Row>

                <Row className='student-info-row'>
                    <Col sm={6} className='student-section'>
                        <img src={userActions.GetFile(content?.students[0]?.icon) === 'http://localhost:5000/api/file/null' ? placeholder : userActions.GetFile(content?.students[0]?.icon)}></img>
                        <h1 className='username'>{content?.students[0]?.username}</h1>
                        <div className='student-info'>
                            <h2>Name</h2>
                            {content?.students[0].first_name && content?.students[0]?.last_name ? 
                                <p>{content?.students[0].first_name}, {content?.students[0]?.last_name}</p> : 
                                <p>No name available</p>}
                        </div>
                    </Col>
                    
                    {/*Popsicles NO IMPLEMENTATION*/}
                    <Col sm={6} className='quest-tracker'>
                        <h1>Quest Tracker</h1>
                        <Row className='quest-popsicles justify-content-center'>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                1
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                2
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                3
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                4
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                5
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                6
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                7
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                8
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                9
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                <img src={incompletePopsicle}></img><br/>
                                10
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/*Progress Bar NO IMPLEMENTATION*/}
                <Row className='student-info-row'>
                    <Col sm={6} className='student-section student-links'>
                        <a>Student Travelogue</a>
                        <a>Student Museum Exhibit</a>
                    </Col>
                    <Col sm={6} className='quest-progress'>
                        <h1>Current Quest Progress</h1>
                        <Row className='justify-content-center'>
                            <Col xs={5} className='current-quest'>
                                <h2>Current Quest</h2>
                                <h3>Quest Name</h3>
                                <p>Quest Start Data: X/X/XX</p>
                                <p>Quest Duration: X:XX</p>
                            </Col>
                            <Col xs={5}>
                                <img src={progressBar}></img>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(StudentDashboard);
export { connection as StudentDashboard };