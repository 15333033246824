import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { Phase } from './Phase';

function Phases({content}) {

    const [phases, setPhases] = useState([<Phase key={0} number={1} isHidden={false} />]);
    const [currentPhase, setCurrentPhase] = useState(0);

    const handleAddPhase = () => {
        addItem(<Phase key={currentPhase} number={currentPhase + 1} isHidden={false} />, currentPhase + 1)
    }

    const handleRemovePhase = () => {
        removeItem();
    }

    const cyclePhase = (direction) => {
        if (currentPhase + direction < 0 || currentPhase + direction >= phases.length) {
            return;
        }
        updateItem(currentPhase, <Phase key={Number(phases[currentPhase].key)} number={phases[currentPhase].props.number} isHidden={true} />, currentPhase + direction, <Phase key={Number(phases[currentPhase + direction].key)} number={phases[currentPhase + direction].props.number} isHidden={false} />)
        setCurrentPhase(currentPhase + direction);
    }

    const updateItem = (id, newvalue, id2, newvalue2) => {
        let temporaryarray = phases.slice();
        temporaryarray[id] = newvalue;
        if(id2 !== undefined) {
         temporaryarray[id2] = newvalue2;
        }
        setPhases(temporaryarray);
    }

    const addItem = (newvalue, newid) => {
        let temporaryarray = phases.slice();

        //This is to fill empty gaps if deletion occured
        temporaryarray.push("");
        for (let i = newid; i < temporaryarray.length; i++) {
            temporaryarray[i] = <Phase key={i} number={i + 1} isHidden={true} />
        }
        temporaryarray[newid] = newvalue

        temporaryarray[currentPhase] = <Phase key={Number(phases[currentPhase].key)} number={phases[currentPhase].props.number} isHidden={true} />;
        setCurrentPhase(newid);
        setPhases(temporaryarray)
    }

    const removeItem = () => {
        if(phases.length < 2) {
            window.alert('Could not remove the only phase')
            return;
        }
        let filteredArray = phases.filter((item, index)=> index !== currentPhase)
        filteredArray[currentPhase - 1] = <Phase key={Number(phases[currentPhase -1].key)} number={phases[currentPhase -1].props.number} isHidden={false} />;
        setCurrentPhase(currentPhase - 1);
        setPhases(filteredArray);
    }

    useEffect(()=>{
        setCurrentPhase(0)
        if(content) {
            let temporaryarray = [];
            for(const x in content) {
                if(Object.keys(content)[0] === x) {
                    temporaryarray[0] = <Phase key={0} number={1} isHidden={false} content={content[x]}/>;
                }
                else {
                    temporaryarray.push(<Phase key={Number(temporaryarray[temporaryarray.length - 1].key) + 1} number={Number(temporaryarray[temporaryarray.length - 1].props.number) + 1} isHidden={true} content={content[x]} />);
                }
            }
            setPhases(temporaryarray);
        }
    },[content])


    // console.log(content)
    // console.log(currentPhase)
    console.log(content)

    return (
        <>
            <div id='phase_container' className='grid-col-12' style={{ margin: 'auto', width: '90%' }}>
                {phases}
                <br></br>
                <div style={{margin: 'auto', width:'100%', display:'flex', justifyContent:'space-around'}}>
                    <button type='button' style={{width: '20%', height: '75px', backgroundColor: 'var(--purple)', color: 'white', fontWeight: 'bold', fontSize: '2em'}} onClick={() => cyclePhase(-1)}>&#10094; Phase </button>
                    <button type='button' style={{width: '20%', height: '75px', backgroundColor: 'green', color: 'white', fontWeight: 'bold', fontSize: '2em'}} onClick={() => handleAddPhase()} > ADD PHASE</button>
                    <button type='button' style={{width: '20%', height: '75px', backgroundColor: 'red', color: 'white', fontWeight: 'bold', fontSize: '2em'}} onClick={() => handleRemovePhase()} > REMOVE PHASE</button>
                    <button type='button' style={{width: '20%', height: '75px', backgroundColor: 'var(--purple)', color: 'white', fontWeight: 'bold', fontSize: '2em'}} onClick={() => cyclePhase(1)}>Phase &#10095;</button>
                </div>
            </div>
        </>
    )
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Phases);
export { connection as Phases };