//--------------------------------------------------
//Dashboard for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DashboardNav } from "../DashboardComponents/DashboardNav";
import { ClassList } from "../DashboardComponents/ClassList";

const Dashboard = ({ }) => {

    document.title = 'Dashboard'

    return (
        <Fragment>
            <div className='main-container page max-height-screen'>

               <DashboardNav active={'classes'}/>
                <ClassList />

            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Dashboard);
export { connection as Dashboard };