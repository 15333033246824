//--------------------------------------------------
//Redux reducer for user session
//--------------------------------------------------

import { sessionConstants } from "./constants";

const INITIALSTATE = {
    defaultVar: '',
    clearance: "unknown",
    token: "",
    useCaptcha: localStorage.getItem('useCaptcha') || null,
    ...verifyToken()
};

//User management
export function session(state = INITIALSTATE, action) {
    switch (action.type) {
        case sessionConstants.REQUEST:
            console.log("Action " + action.info)
            return state
        case sessionConstants.DefaultConstSUCCESS:
            return {...state, defaultVar: action.info};
        case sessionConstants.UseCaptcha:
            localStorage.setItem('useCaptcha', action.info);
            return {...state, useCaptcha: action.info};
        case sessionConstants.ResetEmailSuccess:
            return {...state, resetSent: true};
        case sessionConstants.FindUserSuccess:
            return {...state, userById: action.info};
        case sessionConstants.Logout:
            localStorage.clear();
            return state;
        case sessionConstants.CheckResetCodeSuccess:
            return {...state, accessToken: action.info};
        case sessionConstants.LoginSuccess:
            const payload = JSON.parse(atob(action.info.split('.')[1]));
            localStorage.setItem('token', action.info);
            localStorage.setItem('clearance', payload.clearance);
            return {
                ...state,
                token: action.info,
                clearance: payload.clearance
            };
        default:
            return state;
    }
}

//Checks if the user session token has expired
function verifyToken() {
    let token = localStorage.getItem('token') || "";
    if (token) {
        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            if (payload.exp && typeof payload.exp === 'number') {
                if(Date.now() >= payload.exp * 1000) {
                    localStorage.clear();
                    return {
                        token: "",
                        clearance: 'unknown',
                        warning: 'Your account signin token has expired please sign back in'
                    }
                } else {
                    return {
                        token: token,
                        clearance: payload.clearance
                    };
                }
            }
        } catch (err) {}
    }
    return {
        token: "",
        clearance: "unknown"
    }
}