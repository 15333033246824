//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const ConfirmationPopup = ({ display, student, confirmation }) => {

    const [showBanner, setBanner] = useState('hidden')
    const [studentToRemove, setStudentToRemove] = useState('');


    useEffect(() => {
        setBanner(display);
        setStudentToRemove(student);

    }, [display,student,confirmation])


    return (
        <Fragment>
            <section className={`bannerBackground ${showBanner}`}>
                        <section className={`bannerPopup`}>
                            <div className='header'>Are you sure?</div>
                            <div className='content'>You are removing {studentToRemove}. <br></br> Removing a Student will remove all of their progress and they will need to register again.</div>
                            <div className='buttons'>
                                <button className='purple' onClick={()=>confirmation(false)}>Cancel</button>
                                <button className='red' onClick={()=>confirmation(true)}>Remove</button>
                            </div>
                        </section>
                </section>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ConfirmationPopup);
export { connection as ConfirmationPopup };