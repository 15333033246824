//--------------------------------------------------
//Quest Form for Admins
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { Phases } from '../QuestComponents/AddPhases';

const QuestForm = ({ session }) => {

    document.title = 'Quest Form';

    const [questID, setQuestID] = useState(null);
    const [questName, setQuestName] = useState('');
    const [questDescription, setQuestDescription] = useState('');
    const [questDuration, setQuestDuration] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [questListHidden, setQuestListHidden] = useState('hidden');
    const [quests, setQuests] = useState([]);
    const [currentQuest, setCurrentQuest] = useState(null);

    useEffect(() =>
        userActions.GetQuests(session.token, (data)=>{
            setQuests(data);
            setCurrentQuest(data[0])
        }),
    []);

    const submitQuest = () => {
        let questContent = {};
        let sources = [];
        console.log(document.getElementsByClassName('phase_type').length)
        for (let x = 0; x < document.getElementsByClassName('phase_type').length; x++) {
            const phaseID = 'phase_' + (x + 1)
            questContent[phaseID] = { sources: [] };
            questContent[phaseID].name = document.getElementsByClassName('phase_name')[x].value;
            questContent[phaseID].type = document.getElementsByClassName('phase_type')[x].value;
            questContent[phaseID].category = document.getElementsByClassName('phase_type')[x].options[document.getElementsByClassName('phase_type')[x].selectedIndex].getAttribute('data')
            questContent[phaseID].message = document.getElementsByClassName('phase_message')[x].value;
            const background = document.getElementsByClassName('phase_background')[x];
            questContent[phaseID].background = background.getAttribute('imagename') ? background.getAttribute('imagename') : background.files[0] ? background.files[0].name : '';
            sources.push(document.getElementsByClassName('phase_background')[x].files[0]);
            for (let i = 0; i < document.getElementsByClassName('phase_sources')[x].children.length; i++) {
                const type = document.getElementsByClassName('phase_sources')[x].children[i].children[0].value;
                const source = document.getElementsByClassName('phase_sources')[x].children[i].children[1];
                const citation = document.getElementsByClassName('phase_sources')[x].children[i].children[2];
                const exists = document.getElementsByClassName('phase_sources')[x].children[i].children[3]; //This is to see if image already exists
                if (type === 'Image' && source.files[0]) {
                    sources.push(source.files[0]);
                    questContent[phaseID].sources.push({ type: type, file: source.files[0].name, citation: citation.value ? citation.value : citation.getAttribute('placeholder') });
                }
                else if(exists && type === 'Image') {
                    questContent[phaseID].sources.push({ type: type, file: exists.getAttribute('filename'), citation: citation.value ? citation.value : citation.getAttribute('placeholder') });
                }
                else {
                    questContent[phaseID].sources.push({ type: type, file: source.value, citation: citation.value ? citation.value : citation.getAttribute('placeholder') });
                }
            }
        }

        const form = new FormData();
        for (const x in sources) {
            if (sources[x]) {
                form.append(sources[x].name, sources[x])
            }
        }
        form.append('content', JSON.stringify(questContent));
        form.append('name', questName);
        form.append('description', questDescription);
        form.append('duration', questDuration);
        form.append('isActive', isActive ? 1 : 0)

        //console.log(questContent)

        if(questListHidden === 'hidden') {
            userActions.CreateQuest(session.token, form, (data) => {
                window.alert('Quest Created');
            })
        }
        else {
            userActions.UpdateQuest(session.token, form, questID, (data) => {
                window.alert('Quest Updated');
            })
        }
    }

    const changeForm = (value) => {
        if(value === 'New Quest') {
            window.location.reload()
        }
        else {
            setQuestListHidden('');
        }
    }

    const changeFormContent = (value) => {
        console.log(quests[value])
        setQuestID(quests[value].id)
        setQuestName(quests[value].name);
        setQuestDescription(quests[value].description);
        const minutes = quests[value].duration.split('Minutes')[0];
        setQuestDuration(minutes ? minutes : 0);
        setIsActive(quests[value].isActive ? true : false)
        setCurrentQuest(quests[value]);
    }

    const deleteQuest = () => {
        userActions.DeleteQuest(session.token, questID, (data)=>{
            window.alert(data);
            window.location.reload();
        })
    }


    if (session.clearance !== 'admin') {
        return (
            <Fragment>
                Unauthorized Access
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className='main-container page max-height-screen'>
                <div className='formContainer' style={{ height: 'fit-content' }}>
                    <div style={{ margin: '0% 5%', width: '100%' }}>
                        <select style={{ margin: '0%', width: '200px'}} onChange={(e) => changeForm(e.target.value)}>
                            <option>New Quest</option>
                            <option>Edit Quest</option>
                        </select>
                        <select className={questListHidden} style={{ margin: '0% 5%', width: '200px'}} onChange={(e) => changeFormContent(e.target.value)}>
                            <option disabled selected>Choose Quest to Edit</option>
                            {quests.map((value, index) => {
                                return (
                                    <option key={'quest' + value.id} value={index}>{value.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='form' style={{ display: 'grid' }}>
                        <label className='font-xl font-bold text-purple'>Quest Form &#9757;</label>
                        <form className=''>
                            <input type='text' style={{ width: '90%', margin: '1%' }} placeholder='Quest Name...' onChange={(e) => setQuestName(e.target.value)} value={questName}></input>
                            <input type='number' style={{ width: '90%', margin: '1%' }} placeholder='Duration (minutes)' onChange={(e) => setQuestDuration(e.target.value)} value={questDuration}></input>
                            <textarea placeholder='Quest Description...' style={{ width: '95%', margin: '1%' }} className='grid-col-12' onChange={(e) => setQuestDescription(e.target.value)} value={questDescription}></textarea>


                            <Phases content={currentQuest?.content}></Phases>
                            <div className={'grid-col-2'} style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: '2% 0%' }}>
                                <label for='isActive' className='font-bold font-lg' style={{ color: 'black' }}>Publicize Quest?</label>
                                <input type='checkbox' style={{ width: '50px', height: '50px' }} placeholder='Quest Name...' id='isActive' onChange={(e) => setIsActive(e.target.checked)} checked={isActive}></input>
                            </div>
                        </form>
                        <button style={{ width: '50%', height: '100px', margin: '2% auto', justifySelf: 'center', fontSize: '2.5em', fontWeight: 'bold' }} type='button' onClick={() => submitQuest()}>Submit Quest</button>
                        <button className={`${questListHidden}`} style={{ width: '50%', height: '100px', margin: '2% auto', justifySelf: 'center', fontSize: '2.5em', fontWeight: 'bold' }} type='button' onClick={() => deleteQuest()}>Delete Quest</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(QuestForm);
export { connection as QuestForm };