//--------------------------------------------------
//Quest Message
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const Message = ({ message }) => {

   
    const [content, setContent] = useState(null);



    useEffect(() =>
       setContent(message),
    [message]);

    // console.log(content)

    return (
        <Fragment>
            <div className={'grid-col-2 quest-message purple'}>
                <p>{content}</p>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Message);
export { connection as Message };