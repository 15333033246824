//--------------------------------------------------
//Rendered Component for logging in and registering account
//--------------------------------------------------

import React, { Fragment, useState, useEffect } from 'react';
import { userActions } from "../../Actions";
import { SchoolInput } from "../GeneralComponents/schoolList";
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import env from "react-dotenv";
import { FaArrowLeft, FaKaaba } from "react-icons/fa";

const Login = ({ LoginStudent, LoginTeacher, session }) => {

    document.title = 'Login'

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [student_id, setStudentID] = useState('');
    const [school, setSchool] = useState('');
    const [classroom_code, setClassCode] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);

    const [status, setStatus] = useState(null);

    const [selectionForm, setSelectionForm] = useState('');
    const [studentForm, setStudentForm] = useState('hidden');
    const [teacherForm, setTeacherForm] = useState('hidden');
    const [button, setButton] = useState('');

    const [response, setResponse] = useState(null);

    const login = () => {
        setButton('hidden');
        const { useCaptcha } = session
        if (status === 'teacher')
            session.useCaptcha ? setResponse(LoginTeacher({ email, password, useCaptcha, captchaToken })) : setResponse(LoginTeacher({ email, password }))
        else if (status === 'student')
            session.useCaptcha ? setResponse(LoginStudent({ username, student_id, classroom_code, useCaptcha, captchaToken })) : setResponse(LoginStudent({ username, student_id, classroom_code }))
        setTimeout(() => setButton(''), 500)
    }

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };


    const handleStatusSelection = (selected) => {
        setStatus(selected);
        if (selected == 'teacher') {
            setSelectionForm("hidden")
            setTeacherForm('');
        }
        else {
            setSelectionForm("hidden")
            setStudentForm('')
        }
    }


    const goBack = () => {
        setSelectionForm("")
        setStudentForm('hidden')
        setTeacherForm('hidden');
    }

    console.log(response)

    return (
        <Fragment>
            <div className='homepage page overflow-hidden boise-background'>
                <div className='center register-container'>
                    <div className={`${selectionForm} grid`}>
                        <h1>Account Login</h1>
                        <div className="small-gap section">
                            <h2 className='middleText'>Are you a...</h2>
                            <button className="selection-button" onClick={() => handleStatusSelection('student')}>Student</button>
                            <h2 className='middleText'>or</h2>
                            <button className="selection-button" onClick={() => handleStatusSelection('teacher')}>Teacher</button>
                        </div>
                        <div className='section2'>
                            <h2 className='middleText-small'>Don't have an account?</h2>
                            <a href='/register' className='span-half'><button className="small-button">Sign-Up</button> </a>
                        </div>
                    </div>
                    <div className={`${teacherForm} grid-display`}>
                        <h2 onClick={() => goBack()} className='back'><FaArrowLeft />Back</h2>
                        <h1>Login as Teacher</h1>
                        <input className='full-input' type='email' placeholder='Email...' value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='email'></input>
                        <input className='full-input' type='password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='password'></input>
                        {session.useCaptcha ?
                            <ReCAPTCHA
                                sitekey={env.CAPTCHA_SITE_KEY}
                                onChange={handleCaptchaChange}
                            />
                            : null}
                        <button className={button + " register-button"} onClick={() => login()}>Login</button>
                        <a className='reset-password-button' onClick={() => userActions.reroute("ResetPassword")}>Forgot Password?</a>
                    </div>
                    <div className={`${studentForm} grid-display`}>
                        <h2 onClick={() => goBack()} className='back'><FaArrowLeft />Back</h2>
                        <h1>Login as Student</h1>
                        <input className='full-input' type='text' autoComplete='off' placeholder='Classroom Code...' value={classroom_code} onChange={(e) => setClassCode(e.target.value)}></input>
                        <input className='full-input' type='text' autoComplete='off' placeholder='Username...' value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        <input className='full-input' type='text' autoComplete='off' placeholder='Student Number...' value={student_id} onChange={(e) => setStudentID(e.target.value)}></input>
                        {session.useCaptcha ?
                            <ReCAPTCHA
                                sitekey={env.CAPTCHA_SITE_KEY}
                                onChange={handleCaptchaChange}
                            />
                            : null}
                        <button className={button + " register-button"} onClick={() => login()}>Login</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    LoginStudent: userActions.LoginStudent,
    LoginTeacher: userActions.LoginTeacher,
};

const connection = connect(mapState, actionCreators)(Login);
export { connection as Login };