import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

function Ordering({ content, backgroundImage, togglePhaseButton, isFinished }) {

    const [newOrder, setNewOrder] = useState({});

    function handleOnDrag(e, widgetType) {
        e.dataTransfer.setData("widgetType", widgetType);
    }

    function handleOnDrop(e) {
        const widgetType = e.dataTransfer.getData("widgetType");
        if((e.target.children[1] && !e.target.classList.contains('bank') ) || e.target.classList.contains('order-number') || e.target.nodeName == 'IMG' || e.target.classList.contains('item')) {
            return;
        }
        e.target.append( document.getElementById(widgetType))
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    useEffect(() => {
        console.log(isFinished)
        togglePhaseButton(false);
        let temp = {}
        let usedNumbers = [];
        const RandomSlot = () => {
            const randomNum = Math.floor(Math.random() * content.length);
            if (usedNumbers.includes(randomNum)) {
                return RandomSlot();
            }
            usedNumbers.push(randomNum);
            return randomNum;
        }
        for (const x in content) {
            temp[RandomSlot()] = {
                file: content[x].file,
                citation: content[x].citation,
                correct_slot: Number(x) + 1
            }
        }
        setNewOrder(temp);
    }, [content])

    const CheckAnswers = () => {
        let errors = 0;
        const boxes = document.getElementsByClassName('box');
        console.log(boxes)
        Object.keys(boxes).map((a)=>{
            const slot = boxes[a].id.split('_')[1];
            if(boxes[a].children[1] && slot == newOrder[Number(boxes[a].children[1].id)].correct_slot) {
                boxes[a].style.border = 'solid #07651c 3px'
            }
            else {
                boxes[a].style.border = 'solid #BF2D2D 3px'
                 errors++;
            }
        })

        if(errors < 1) {
            togglePhaseButton(true);
        }
    }

   // console.log(widgets)
    // console.log(content);

    return (
        <>
            <div className='full ordering-container' id='sources' style={{ backgroundImage: `url(${userActions.GetFile(backgroundImage)})`}} >
                <h2 className='grid-col-12' style={{ margin: 'auto', fontSize: '1.5em', textAlign: 'center', color: 'black' }}>Objective: Analyze the sources and put them into chronological order. Press 'Check Answers' when you believe you have it correct. Then move to the next phase.</h2>
                <div id='ordering' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', contain: 'inline-size', maxWidth: '100%', maxHeight: '100%' }}>
                    {content.map((value, index) => {
                        return (
                            <div id={'box_'+(index+1)} className='box ordering-box' key={index+'box'} style={{}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                                <p className='order-number'>{index + 1}</p>
                                {isFinished ? 
                                    <img src={userActions.GetFile(value.file)}></img>
                                :
                                ''
                                }
                            </div>
                        )
                    })}
                </div>
                <div className='bank' onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    {!isFinished ? Object.keys(newOrder).map((value, index) => {
                        return (
                            <div key={value+index} id={value} className='item' style={{ contain: 'content', margin: '5%', display: 'inline-flex', flexFlow: 'wrap' }} draggable onDragStart={(e)=>handleOnDrag(e, value)}>
                                <img src={userActions.GetFile(newOrder[value].file)} style={{ width: '60%', margin: 'auto' }}></img>
                                {/* <select id={value} style={{margin: 'auto', width: '25%', justifySelf: 'center', padding: '1%'}} onChange={(e) => {e.target.style.border = ''}}>
                                    {Object.keys(newOrder).map((value, index) => {
                                        return (
                                            <option>{index + 1}</option>
                                        )
                                    })}
                                </select> */}
                            </div>
                        )
                    }) : ''}
                </div>
                <div style={{display: 'grid', width: '100%'}}>
                    <button style={{justifySelf: 'center', width: '33%', border: 'none', borderRadius: '.5em', backgroundColor: '#593f75', height:'50px', margin: '2%', color: 'white', fontSize: '1.25em', fontWeight: 'bold'}}  onClick={()=>CheckAnswers()}>Check Answers</button>
                </div>
            </div>
        </>
    )
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Ordering);
export { connection as Ordering };