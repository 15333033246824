
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

const DashboardNav = ({active, session}) => {

    const [settings, setSettings] = useState('');
    const [classes, setClasses] = useState('');
    const [lessons, setLessons] = useState('');
    const [scrollTop, setScrollTop] = useState(0);
    const componentRef = useRef(null);

    useEffect(()=> {
        
        switch (active) {
            case 'settings':
            setSettings('active');
                break;
            case 'classes':
                setClasses('active');
                break;
            case 'lessons':
                setLessons('active');
                break;
            default:
                
        }
    })

    return (
        <Fragment>
            <nav className={'dashboard-nav'} ref={componentRef}>
                <div className={'header'}>IDA GEM</div>
                <div className={'nav'}>
                    <a href='/Dashboard' className={classes}>Dashboard</a>
                    <a href={'/settings'} className={settings}>Settings</a>
                </div>
            </nav>
        </Fragment>
    )
};


function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(DashboardNav);
export { connection as DashboardNav };