//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const ClassCreationForm = ({ display, confirmation }) => {

    const [showBanner, setBanner] = useState('hidden')
    const [className, setClassName] = useState('');
    const [classGrade, setClassGrade] = useState('');
    const [classIcon, setClassIcon] = useState('');


    useEffect(() => {
        setBanner(display);

    }, [display, confirmation])

    const onIconUpload = (icon) => {
        // const form = new FormData();
        // form.append('file', icon);
        // userActions.Upload(form, (err, message, data) => {
        //     console.log('message ' + message)
        //     console.log('error ' + err)
        //     console.log(data)
        //     if(!err) {
        //         setClassIcon(data.data);
        //     }
        // })

        setClassIcon(icon);
    }


    return (
        <Fragment>
            <section className={`bannerBackground ${showBanner}`}>
                <section className={`createClass`}>
                    <h1>Create a Class</h1>
                    <div className='inputRow'>
                        <label>Classroom Name</label>
                        <input type='text' value={className} onChange={(e) => setClassName(e.target.value)} placeholder='Classname Here'></input>
                    </div>
                    <div className='inputRow'>
                        <label>Classroom Grade</label>
                        <input type='text' value={classGrade} onChange={(e) => setClassGrade(e.target.value)} placeholder='Class Grade Here'></input>
                    </div>
                    <div className='inputRow'>
                        <label>Classroom Icon</label>
                        <input type='file' onChange={(e) => onIconUpload(e.target.files[0])}></input>
                    </div>
                    <div className='buttons'>
                        <button className='red' onClick={() => confirmation(false)}>Cancel</button>
                        <button className='purple' onClick={() => confirmation(true, className, classGrade, classIcon)}>Create</button>
                    </div>
                </section>
            </section>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ClassCreationForm);
export { connection as ClassCreationForm };