//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const ApprovalList = ({ key }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const token = localStorage.getItem("token");
    
    const [content, setContent] = useState(null);


    useEffect(() =>
        GetApprovals(),
    []);

    function GetApprovals(){
        userActions.GetApprovals(classroom_id, params.get("student"), token, (data) => {
            //console.log(data);
            setContent(data);
        })
    }

    function approveContent(approval_id, student_id){
        userActions.ApproveContent(approval_id, classroom_id, student_id, token, (data) => {
            GetApprovals();
        })
    }

    function denyContent(approval_id, student_id){
        userActions.DenyContent(approval_id, classroom_id, student_id, token, (data) => {
            GetApprovals();
        })
    }

    console.log(content)

    return (
        <Fragment>

            <div className={'studentView'} key={key}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='sectionHeader'>Aprroval Requests: <span className='text-black'>({content?.responses?.length})</span></h1>
                </div>
                <div className='studentContainer'>
                    {content?.responses?.length > 0 ? content.responses?.map((index, num) => {
                        return (
                            <div key={index+num} className='approvalRow'>
                                <div className='approvalRowHeader'>
                                    <div>{index.username} : ID {index.student_id}<span className='bold font-lg'>Quest {index.quest}, Phase {index.phase}</span></div>
                                    <div>
                                        <button
                                            onClick={() => approveContent(index.id, index.user_id)}
                                            className='approvalButtonApprove'
                                        >
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => denyContent(index.id, index.user_id)}
                                            className='approvalButtonDeny'
                                        >
                                            Deny
                                        </button>
                                    </div>
                                </div>
                                <div className='approvalContent'>
                                    {Object.keys(index.content).map((value)=>{
                                        return (
                                            <div key={value+num}>
                                                {value} : {index.content[value]}
                                                </div>
                                        )
                                    })
                                    }
                                    </div>
                            </div>
                        )
                    })
                :
                <div className='studentRow'>
                    <span className='bold font-lg grid-col-12'>This {params.get("student") ? 'student' : 'class'} has not yet completed any phases!</span>
                </div>
                } 
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ApprovalList);
export { connection as ApprovalList };