import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

function Matching({ content, backgroundImage, togglePhaseButton, isFinished }) {

    const [newOrder, setNewOrder] = useState({})
    const [staticFiles, setStaticFiles] = useState([])

    function handleOnDrag(e, widgetType) {
        e.dataTransfer.setData("widgetType", widgetType);
    }

    function handleOnDrop(e) {
        const widgetType = e.dataTransfer.getData("widgetType");
        if ((e.target.children[1] && !e.target.classList.contains('bank')) || e.target.nodeName == 'IMG' || e.target.classList.contains('item')) {
            return;
        }
        e.target.append(document.getElementById(widgetType))
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    useEffect(() => {
        togglePhaseButton(false);
        let temp = {}
        let temp2 = [];
        let usedNumbers = [];
        const RandomSlot = () => {
            const randomNum = Math.ceil(Math.random() * (content.length / 2));
            if (usedNumbers.includes(randomNum)) {
                return RandomSlot();
            }
            usedNumbers.push(randomNum);
            return randomNum;
        }
        for (const x in content) {
            if (x % 2 == 0) {
                temp[RandomSlot()] = {
                    file: content[x].file,
                    citation: content[x].citation,
                    correct_slot: Number(x) == 0 ? Number(x) + 1 : Math.ceil((Number(x) + 1) / 2)
                }
            }
            else {
                temp2.push(content[x])
            }
        }
        setNewOrder(temp);
        setStaticFiles(temp2)
    }, [content])

    const CheckAnswers = () => {
        let errors = 0;
        const boxes = document.getElementsByClassName('box');
        //console.log(newOrder)
        Object.keys(boxes).map((a) => {
            const slot = boxes[a].id.split('_')[1];
            if (boxes[a].children[1] && slot == newOrder[Number(boxes[a].children[1].id)].correct_slot) {
                boxes[a].style.border = 'dashed green 2px'
            }
            else {
                boxes[a].style.border = 'dashed red 2px'
                errors++;
            }
        })

        if (errors < 1) {
            togglePhaseButton(true);
        }
    }

    // console.log(widgets)
    // console.log(content);

    return (
        <>
            <div className='full' style={{ backgroundImage: `url(${userActions.GetFile(backgroundImage)})`, backgroundSize: 'cover' }} id='sources'>
                <h2 className='grid-col-12' style={{ margin: 'auto', fontSize: '1.5em', textAlign: 'center' }}>Objective: Analyze the sources and put them into chronological order. Press 'Check Answers' when you believe you have it correct. Then move to the next phase.</h2>
                <div style={{ display: 'grid', contain: 'inline-size', maxWidth: '100%', maxHeight: '100%' }}>
                    {staticFiles.map((value, index) => {
                        return (
                            <div style={{display: 'flex'}}>
                                <p style={{width: '33%', textAlign: 'center', margin: 'auto'}}>{value.file}</p>

                                <hr style={{height: '5px', width: '50%', margin: 'auto'}}></hr>

                                <div id={'box_' + (index + 1)} className='box' key={index + 'box'} style={{ position: 'relative', contain: 'content', margin: '5%', display: 'inline-flex', flexFlow: 'wrap', border: 'dashed white 2px', height: '90%', width: '33%', minHeight: '200px' }} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                                    <p style={{ position: 'absolute', fontWeight: 'bold', fontSize: '2em', margin: '1%' }}>{index + 1}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='bank' style={{ position: 'relative', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', contain: 'inline-size', maxWidth: '90%', height: 'auto', minHeight: '25%', border: 'solid white 2px', margin: 'auto' }} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <p style={{ position: 'absolute', margin: '1%', fontWeight: 'bold' }}>Image Bank</p>
                    {Object.keys(newOrder).map((value, index) => {
                        return (
                            <div key={value + index} id={value} className='item' style={{ contain: 'content', margin: '5%', display: 'inline-flex', flexFlow: 'wrap' }} draggable onDragStart={(e) => handleOnDrag(e, value)}>
                                <img src={userActions.GetFile(newOrder[value].file)} style={{ width: '80%', margin: 'auto' }}></img>
                                {/* <select id={value} style={{margin: 'auto', width: '25%', justifySelf: 'center', padding: '1%'}} onChange={(e) => {e.target.style.border = ''}}>
                                    {Object.keys(newOrder).map((value, index) => {
                                        return (
                                            <option>{index + 1}</option>
                                        )
                                    })}
                                </select> */}
                            </div>
                        )
                    })}
                </div>
                <div style={{ display: 'grid', width: '100%' }}>
                    <button style={{ justifySelf: 'center', width: '25%' }} onClick={() => CheckAnswers()}>Check Answers</button>
                </div>

            </div>
        </>
    )
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Matching);
export { connection as Matching };