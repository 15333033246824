//--------------------------------------------------
//Template page to copy/paste for new pages
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../../Actions";
import { connect } from 'react-redux';
import { FullscreenImage } from '../../GeneralComponents/FullscreenImage';

import ReactPlayer from 'react-player';
import { AiFillSound } from "react-icons/ai";
import { FaPlay, FaPause, FaStop, FaExpand } from "react-icons/fa"

const QuestTemplate = ({ }) => {
    const [textSegments, setTextSegments] = useState([]);
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(0);
    const [TTSPlaying, setTTSPlaying] = useState(false);
    const [TTSEnabled, setTTSEnabled] = useState(false);
    const [fullscreenOpen, setFullscreenOpen] = useState(false);

    useEffect(() => {
        fetch("/TextFiles/testText.txt")
            .then((response) => response.text())
            .then((data) => {
                const paragraphs = data.split(/\n\s*\n/);
                setTextSegments(paragraphs);
            });
        
        setImages(["/Images/testimage.jpg", "/Images/testimage2.jpg", "/Images/testimage3.jpg"])
    }, []);

    const openFullScreen = (img) => {
        setSelectedImage(img)
        setFullscreenOpen(true)
    }

    return (
        <Fragment>
            <FullscreenImage imageUrl={images[selectedImage]} visible={fullscreenOpen} setVisible={setFullscreenOpen} allImages={images.length} selectedImage={selectedImage} setSelectedImage={setSelectedImage}/>

            <div className='main-container defaultpage'>
                <div className='border-container'>
                    <h1>Quest Template</h1>
                </div>

                {!TTSEnabled ?
                <button onClick={() => {
                        const utterance = new SpeechSynthesisUtterance(textSegments[0]);
                        speechSynthesis.speak(utterance);
                        console.log(utterance)
                        setTTSEnabled(true);
                        setTTSPlaying(true);
                    }}
                ><AiFillSound color={"black"}/></button>
                :
                <div>
                    <button onClick={() => {
                            if(TTSPlaying){
                                speechSynthesis.pause();
                                setTTSPlaying(false);
                            } else {
                                speechSynthesis.resume();
                                setTTSPlaying(true);
                            }
                        }}
                    >{TTSPlaying ? <FaPause /> : <FaPlay />}</button>

                    <button onClick={() => {
                        speechSynthesis.cancel();
                        setTTSEnabled(false);
                    }}><FaStop /></button>
                </div>
                }

                <div className='container'>
                    <p>{textSegments[0]}</p>

                    <p>{textSegments[1]}</p>

                    <p>{textSegments[2]}</p>

                    <div className='activity-image'>
                        <ReactPlayer style={{marginLeft: "auto", marginRight: "auto"}} url="/Videos/testvideo.mp4" controls={true}/>
                    </div>

                    <div className='activity-image'>
                            <button 
                                className='fullscreen-button'
                                onClick={() => openFullScreen(0)}
                            >
                                <FaExpand size={"2em"}/>
                            </button>
                        <img
                            style={{marginLeft: "auto", marginRight: "auto"}}
                            width={"50%"}
                            src={images[0]} 
                        />
                    </div>

                    <div className='activity-image'>
                            <button 
                                className='fullscreen-button'
                                onClick={() => openFullScreen(1)}
                            >
                                <FaExpand size={"2em"}/>
                            </button>
                        <img
                            style={{marginLeft: "auto", marginRight: "auto"}}
                            width={"50%"}
                            src={images[1]} 
                        />
                    </div>

                    <div className='activity-image'>
                            <button 
                                className='fullscreen-button'
                                onClick={() => openFullScreen(2)}
                            >
                                <FaExpand size={"2em"}/>
                            </button>
                        <img
                            style={{marginLeft: "auto", marginRight: "auto"}}
                            width={"50%"}
                            src={images[2]} 
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(QuestTemplate);
export { connection as QuestTemplate };