//--------------------------------------------------
//Rendered Component for logging in and registering account
//--------------------------------------------------

import React, { Fragment, useState, useEffect } from "react";
import { userActions } from "../../Actions";
import { SchoolInput } from "../GeneralComponents/schoolList";
import { connect } from "react-redux";
import { FaArrowLeft, FaTruckLoading } from "react-icons/fa";

const Register = ({ RegisterStudent, RegisterTeacher, session }) => {

  document.title = 'Register';

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [school, setSchool] = useState("");
  const [classroom_code, setClassCode] = useState(null);
  const [classImage, setClassImage] = useState(null);
  const [student_id, setStudentID] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [status, setStatus] = useState(null);

  const [selectionForm, setSelectionForm] = useState("");
  const [studentForm, setStudentForm] = useState("hidden");
  const [teacherForm, setTeacherForm] = useState("hidden");
  const [button, setButton] = useState("");
  const [studentConfirm, setStudentConfirm] = useState("hidden");

  const [response, setResponse] = useState(null);
  const [classToJoin, setClassToJoin] = useState({name: '', icon: ''});

  const studentRegisterConfirm = React.useRef(null);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const register = () => {
    setButton("hidden");
    setResponse(
      RegisterTeacher({ first_name, last_name, email, password, school })
    );
    setTimeout(() => setButton(""), 500);
  };

  const RegisterStudentConfirm = () => {

    userActions.GetClassroomName(classroom_code, (data) => {
      console.log(data)
      setClassToJoin(data);
      setClassImage(data.icon);
    })


    setStudentForm("hidden");
    setStudentConfirm("");

    studentRegisterConfirm.current.onclick = () => {
      setResponse(RegisterStudent({ student_id, classroom_code }));
    };
  };

  const handleStatusSelection = (selected) => {
    setStatus(selected);
    if (selected == "teacher") {
      setSelectionForm("hidden");
      setTeacherForm("");
    } else {
      setSelectionForm("hidden");
      setStudentForm("");
    }
  };

  const goBack = () => {
    setSelectionForm("");
    setStudentForm("hidden");
    setTeacherForm("hidden");
    setStudentConfirm("hidden");
    setClassToJoin(null);
  };

  const goBackFromConfirmation = () => {
    setSelectionForm("hidden");
    setStudentForm("");
    setTeacherForm("hidden");
    setStudentConfirm("hidden");
    setClassToJoin(null);
  };

  return (
    <Fragment>
      <div className="homepage page overflow-hidden boise-background">
        <div className="center register-container">
          <div className={`${selectionForm} grid`}>
            <h1>Sign-Up</h1>
            <div className="small-gap section">
              <h2 className='middleText'>Are you a...</h2>
              <button className="selection-button" onClick={() => handleStatusSelection('student')}>Student</button>
              <h2 className='middleText'>or</h2>
              <button className="selection-button" onClick={() => handleStatusSelection('teacher')}>Teacher</button>
            </div>
            <div className='section2'>
              <h2 className='middleText-small'>Already have an account?</h2>
              <a href='/login' className='span-half'><button className="small-button">Sign-In</button> </a>
            </div>
          </div>
          <div className={`${teacherForm} grid-display`}>
            <h2 onClick={() => goBack()} className="back">
              <FaArrowLeft />
              Back
            </h2>
            <h1>Register as Teacher</h1>
            <SchoolInput returnSchool={setSchool} />
            <div className="space-between">
            <input
              className="half-input"
              type="text"
              placeholder="First name..."
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              autoComplete="given-name"
            ></input>
            <input
              className="half-input"
              type="text"
              placeholder="Last name..."
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              autoComplete="family-name"
            ></input>
            </div>
            <input
              className="full-input"
              type="email"
              placeholder="Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            ></input>
            <input
              className="full-input"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            ></input>
            <button
              className={button + " register-button"}
              onClick={() => register()}
            >
              Register
            </button>
          </div>
          <div className={`${studentForm} grid-display`}>
            <h2 onClick={() => goBack()} className="back">
              <FaArrowLeft />
              Back
            </h2>
            <h1>Register as Student</h1>
            <input
              className="full-input"
              type="text"
              placeholder="Classroom Code..."
              value={classroom_code}
              onChange={(e) => setClassCode(e.target.value)}
            ></input>
            <input
              className="full-input"
              type="text"
              placeholder="Student Number (from teacher)..."
              value={student_id}
              onChange={(e) => setStudentID(e.target.value)}
            ></input>
            <button
              disabled={!(classroom_code && student_id)}
              className={button + " register-button"}
              onClick={() => RegisterStudentConfirm()}
            >
              Register
            </button>
          </div>
          <div className={`${studentConfirm} grid-display`}>
            <h2 onClick={() => goBackFromConfirmation()} className="back">
              <FaArrowLeft />
              Back
            </h2>
            <h1>Register as Student</h1>
            <img src={classImage}></img>
            <section className="content">
              You are joining <span>{classToJoin?.name}</span>. <br></br>Is this correct?
            </section>
            <button
              className={button + " register-button"}
              ref={studentRegisterConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {
  RegisterStudent: userActions.RegisterStudent,
  RegisterTeacher: userActions.RegisterTeacher,
};

const connection = connect(mapState, actionCreators)(Register);
export { connection as Register };
