import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FaCompressArrowsAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const FullscreenImage = ({ visible, imageUrl, setVisible, allImages, selectedImage, setSelectedImage }) => {
    const imgMax = allImages;

    const switchImage = (i) => {
        let img = 0;
        if(selectedImage + i >= imgMax){
            img = 0;
        } else if ( selectedImage + i == -1){
            img = allImages.length();
        } else {
            img = selectedImage + i;
        }

        setSelectedImage(img);
    }
    return (
        <div>
            {visible &&
                <div className='fullscreen-container'>

                    {/* close button */}
                    <button
                        className='close-fullscreen-button'
                        onClick={() => {
                            setVisible(false);
                        }}
                    >
                        <FaCompressArrowsAlt color={"white"} size={"2em"}/>
                    </button>

                    {/* left arrow */}
                    <button
                        className='arrow-button left-arrow-button'
                        onClick={() =>
                            switchImage(-1)
                        }
                    >
                        <FaArrowLeft color={"white"} size={"3em"}/>
                    </button>

                    {/* right arrow */}
                    <button
                        className='arrow-button right-arrow-button'
                        onClick={() =>
                            switchImage(1)
                        }
                    >
                        <FaArrowRight color={"white"} size={"3em"}/>
                    </button>

                    {/* image */}

                    <img
                        src={imageUrl}
                    />
                </div>
            }
        </div>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {};

const connection = connect(mapState, actionCreators)(FullscreenImage);
export { connection as FullscreenImage };