//--------------------------------------------------
//Navbar for the application
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { userActions } from '../../Actions';
import { FaRegUser } from 'react-icons/fa';
import placeholder from '../../Images/placeholder.jpg'

const NavigationBar = ({ Logout, session }) => {

    const [userData, setUserData] = useState({
        icon: '',
        last_name: 'Account',
        first_name: '[Name]',
        username: '[Username]'
    })

    useEffect(() => {
        userActions.GetUserData(session.token, (data) => {
            if (data) {
                setUserData(data)
            }
        })
    }, session)

    return (
        <Fragment>
            {['md'].map((expand) => (
                <Navbar key={expand} expand={expand} className="nav-bg purple" sticky='top'>
                    <Container fluid className='purple'>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            backdrop={false}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Menu
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3 purple" style={{height: '50px'}}>
                                    {/* <Nav.Link href="/unity">Unity</Nav.Link> */}
                                    {
                                        !session.token ?
                                        <FaRegUser color={'white'} size={'2em'} style={{alignSelf: 'center'}}/>
                                        :
                                        <div className='nav-img'>
                                            <img src={userActions.GetFile(userData.icon) === 'http://localhost:5000/api/file/null' ? placeholder : userActions.GetFile(userData.icon)}></img>
                                        </div>
                                    }
                                    <NavDropdown
                                        title={userData.status == 'teacher' ? userData.last_name : userData.username}
                                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        className='nav-dropdown' style={{alignSelf: 'center'}}
                                    >
                                        {userData.status == 'teacher' ?
                                            <>
                                            <NavDropdown.Item href="dashboard">Dashboard</NavDropdown.Item>
                                            <NavDropdown.Item href="settings">Settings</NavDropdown.Item>
                                            </>
                                            : userData.status == 'student' ?
                                            <NavDropdown.Item href="game/">Game</NavDropdown.Item>
                                            :''
                                        }
                                        
                                        <NavDropdown.Divider />
                                        {
                                            !session.token ? 
                                            <>
                                            <NavDropdown.Item href="register">Register</NavDropdown.Item> 
                                            <NavDropdown.Item href="login">Login</NavDropdown.Item> 
                                            </>
                                            :
                                                <>
                                                <NavDropdown.Item onClick={() => Logout()}>
                                                    Logout
                                                </NavDropdown.Item>
                                                </>
                                        }

                                    </NavDropdown>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    Logout: userActions.Logout,
};

const connection = connect(mapState, actionCreators)(NavigationBar);
export { connection as NavigationBar };