//--------------------------------------------------
//Quest Sources
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { FaArrowLeft, FaArrowRight, FaCamera } from "react-icons/fa";

const Sources = ({ sources, togglePhaseButton, backgroundImage, type, category, message, AddSnapshotSource, AddTeachMoreSource }) => {


    const [content, setContent] = useState(null);
    const [currentImagePos, setCurrentImagePos] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);
    const [currentCitation, setCurrentCitation] = useState('Image Citation');
    const [currentType, setCurrentType] = useState('Text');

    const [disableRightArrow, setDisableRightArrow] = useState('');
    const [disableLeftArrow, setDisableLeftArrow] = useState('arrows-grey');

    const [adaptation, setAdaptation] = useState('');
    const [adaptationCitation, setAdaptationCitation] = useState('');
    const [toggle, setToggle] = useState(false);

    const [objectivePopup, setObjectivePopup] = useState('hidden');


    const [objective, setObjective] = useState('')

    const [isSnapshot, setIsSnapshot] = useState('hidden');
    const [isTeachMore, setIsTeachMore] = useState('hidden');
    const [snapshotSource, setSnapshotSource] = useState('');

    useEffect(() => {
        setIsTeachMore('hidden')
        setIsSnapshot('hidden');
        setContent(sources);
        setCurrentImage(null);
        setCurrentImagePos(0)
        resetArrows();
        if (!sources || sources?.length < 1) {
            setCurrentCitation('');
            togglePhaseButton(true);
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
        }
        else {
            setCurrentType(sources[0].type)
            const temp = ['Text', 'Video']
            if (temp.includes(sources[0].type)) {
                setCurrentImage(sources[0].file);
            }
            else {
                setCurrentImage(userActions.GetFile(formateFileName(sources[0].file)));
            }
            setCurrentCitation(sources[0].citation)
            setCurrentImagePos(0);
        }
        console.log(sources)
        if (sources?.length < 2) {
            setAdaptation('');
            setAdaptationCitation('');
            togglePhaseButton(true);
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
        }else if(sources){
            setAdaptation(sources[1]?.type == 'Adaptation' ? sources[1]?.file : '');
            setAdaptationCitation(sources[1]?.type == 'Adaptation' ? sources[1]?.citation : '');
        }


        SetPhaseObjective(category)
    }, [sources]);

    const moveImage = (direction) => {

        if(direction > 0 && disableRightArrow == 'arrows-grey') {
            return;
        }
        if(direction < 0 && disableLeftArrow == 'arrows-grey') {
            return;
        }

        setAdaptation('');
        setAdaptationCitation('');
        if (content.length <= currentImagePos + direction) {
            return;
        }
        else if (currentImagePos + direction < 0) {
            return;
        }
        setDisableLeftArrow(currentImagePos + direction <= 0 ?'arrows-grey' : '');
        if(content.length <= currentImagePos + direction + 1 || (content[content.length - 1].type == 'Adaptation' && currentImagePos + direction == content.length - 2)) {
            setDisableRightArrow('arrows-grey')
        }
        else {
            setDisableRightArrow('')
        }
        setCurrentType(content[currentImagePos + direction].type)

        if (content[currentImagePos + direction].type == 'Adaptation') {
            moveImage(direction * 2);
            return;
        }

        const temp = ['Text', 'Video']
        if (temp.includes(content[currentImagePos + direction].type)) {
            setCurrentImage(content[currentImagePos + direction].file);
            if (content[currentImagePos + (direction + 1)] && content[currentImagePos + (direction + 1)].type == 'Adaptation') {
                setAdaptation(content[currentImagePos + (direction + 1)].file)
                setAdaptationCitation(content[currentImagePos + (direction + 1)].citation);
                //Spawn button to toggle
            }
        }
        else {
            setCurrentImage(userActions.GetFile(formateFileName(content[currentImagePos + direction].file)));
        }

        setCurrentCitation(content[currentImagePos + direction].citation)
        setCurrentImagePos(currentImagePos + direction);
        if (content.length <= currentImagePos + direction + 1) {
            togglePhaseButton(true);
        }
    }

    const resetArrows = () => {
        setDisableLeftArrow('arrows-grey');
        setDisableRightArrow('');
    }

    const formateFileName = (file) => {
        let temp = file.replace('#', '%23');
        return temp;
    }

    const SetPhaseObjective = (category) => {
        switch (category) {
            case 'three_type':
                setObjective('Analyze the following sources and write 1 ' + type.split(', ')[0] + ', ' + type.split(', ')[1] + ', and ' + type.split(', ')[2] + ' for each in your Idaho Travelogue. Use proper sentences to continue. Each sentence will grant 1 gem, so write as many as you can! Press enter to type on a new line for the next source.');
                break;
            case 'two_type':
                setObjective('Analyze the following sources and write 1 ' + type.split(' and ')[0] + ' and ' + type.split(' and ')[1] + ' for each in your Idaho Travelogue. Use proper sentences to continue. Each sentence will grant 1 gem, so write as many as you can! Press enter to type on a new line for the next source.');
                break;
            case 'story':
                setObjective("In your Idaho Travelogue, use what you have learned from the watchman to write a short fictional story from the perspective of someone shown in one of these photographs.")
                break;
            case 'letter':
                setObjective("Now, carefully examine the following documents that highlight some of the problems Lake Coeur d’Alene is facing today. In your Idaho Travelogue, write a letter to the editor of the Coeur d’Alene Press with your ideas about what should be done so the area can be enjoyed for many years to come. In your letter, include key information about the history of this area, current issues, and what different people and groups might do to help.")
                break;
            case 'poem':
                setObjective("Now, read or listen to four quotes about the event. Why do you think there were different perspectives? Do you think it could have been possible for the leaders to understand each other's points of view? Select 5-10 words from each quote that stand out as being very important to the speaker’s perspective. Write these in your Idaho Travelogue using a comma to separate them. Then combine and rearrange the 20 - 40 words to create a poem that embodies all of the four individual’s ideas. You are welcome to add punctuation and a few of your own words to help your poem make sense. Remember to also give your poem a title. ")
                break;
            case 'traits':
                setObjective('Write down as many words or sentences that you feel are traits of the topic. See below for the topic. Each word awards 1 gem, each sentence awards 4.')
                break;
            case 'phrase':
                setObjective(' Select a Notable Quote, Phrase, or Fact from the sources, type it in your Travelogue. Then write a sentence explaining why you chose this quote, phrase, or fact. Do this at least 1 time, up to 2 times for 8 gems each.')
                break;
            case 'questions':
                setObjective("Respond to the statement(s) and/or question(s) found below.")
                break;
            case 'details':
                setObjective("Write a detail you've found for each of the following sources in the 'Details' column found in your Travelogue. Also write names of individuals or groups found in the 'Perspectives' column. ")
                break;
            case 'snapshot':
                setObjective(`Cycle through all the sources analyzed in this Quest. Find a source you resonate with and click the Snapshot Button. Once you click the button, answer the questions about the source. 
                    
                    What did you choose to take a picture of? 
Why did you choose this item? 
What do you want to remember about this artifact? 
`)
                setIsSnapshot('')
                break;
                case 'teach_me_more':
                    setObjective(`Cycle through all the sources analyzed in this Quest. Find a source you resonate with and click the Snapshot Button. Once you click the button, answer the questions about the source. 
                        
                        What did you choose to take a picture of? 
    Why did you choose this item? 
    What do you want to remember about this artifact? 
    `)
                    setIsTeachMore('')
                    break;
            default:
                setObjective('Read the following sources before continuing to the next phase.\n Feel free to write notes in your Idaho Travelogue!');
                break;
        }
    }

    const SnapSource = (target) => {

        if(category !== 'snapshot') {
            setIsSnapshot('hidden');
            return;
        }

        if(snapshotSource) {
            setDisableLeftArrow('');
            setDisableRightArrow('');
            setSnapshotSource('');
        }
        else {
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
            setSnapshotSource(currentImage);
            AddSnapshotSource(currentImage);
        }
        
    }

    const AddTeachMore = (target) => {

        if(category !== 'teach_me_more') {
            setIsTeachMore('hidden');
            return;
        }

        AddTeachMoreSource(currentImage);

    }

    //console.log(content)

    return (
        <Fragment>
            <div className='full quest-source-container' style={{ backgroundImage: `url(${userActions.GetFile(backgroundImage)})`, backgroundSize: 'cover', backgroundColor: '#FFE7DC', maxHeight: '100%' }} id='sources'>
                <h2 className='grid-col-12' style={{overflowY: 'scroll', margin: '2%', fontSize: '1.25em', textAlign: 'center', backgroundColor: 'white', borderRadius: '0.5em', padding: '1%', color: 'black', boxShadow: 'inset 0px 9px 13px -8px rgba(0,0,0,0.5)', top: '0px' }}>{message}</h2>
                <section className='grid-col-12 panel-shadow' style={{ display: 'grid', gridTemplateColumns: '1fr 8fr 1fr', gridTemplateRows: '1fr', backgroundColor: 'white', margin: '2% 2% 4% 2%', position: 'relative', maxHeight: '100%'}}>
                    <FaArrowLeft className={`side-arrows ${disableLeftArrow}`} onClick={() => moveImage(-1)} />
                    <div style={{ backgroundColor: '#ebebeb', height: '90%', color: 'black', margin: 'auto', display: 'grid', gridTemplateRows: '1fr auto', maxHeight: '550px', width: '100%', borderRadius: '0.5em', padding: '1%' }}>
                        {currentType == 'Text' ? <p style={{ margin: '2% 0%', maxHeight: '80%', overflowY: 'scroll' }}><span style={{ fontSize: '2em', fontWeight: 'bold' }}>Source:</span><br></br>{toggle ? adaptation : currentImage}</p> : currentType == 'Video' ? <iframe src={currentImage} style={{ height: '100%', width: '100%' }} controls allow={'autoplay'}></iframe> : <img src={currentImage} style={{ margin: '1% auto 0% auto', maxWidth: '100%', height: '-webkit-fill-available', maxHeight: '400px' }}></img>}
                        <p style={{ margin: '1% 0%' }}><span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Citation:</span><br></br> {toggle ? adaptationCitation : currentCitation}</p>
                        <div className='stat inline-flex' style={{ display: adaptation != '' ? 'flex' : 'none', position: 'absolute', justifySelf: 'end', padding: '1%' }}>
                            <label className="switch">
                                <input type="checkbox" onChange={(e) => setToggle(e.target.checked)} checked={toggle ? true : false}></input>
                                <span className="slider round"></span>
                            </label>
                            <span>Adapted Version</span>
                        </div>
                    </div>

                    <div className='stat inline-flex' style={{ display: 'flex', position: 'absolute', justifySelf: 'end', margin: '-1%', cursor: 'pointer'}}>
                            <p style={{fontSize: '2em', color: 'black', fontWeight: 'bold', margin: 'auto 1%'}}>Objective</p>
                            <svg width="106" height="108" viewBox="0 0 106 108" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>setObjectivePopup('')}>
                                <path d="M40.4126 10.2308L42.7699 13.4624L40.4126 10.2308C39.5543 10.8569 38.5189 11.1933 37.4565 11.1913L31.359 11.1797C25.7088 11.1689 20.6987 14.809 18.9629 20.186L22.7695 21.4148L18.9629 20.186L17.0897 25.9886C16.7633 26.9997 16.1234 27.8804 15.2627 28.5033L10.3229 32.0779C5.74551 35.3903 3.8318 41.2801 5.58803 46.6504L7.4833 52.4459C7.81352 53.4556 7.81352 54.5444 7.4833 55.5541L5.58803 61.3496C3.8318 66.7199 5.74551 72.6097 10.3229 75.9221L15.2627 79.4967C16.1234 80.1196 16.7633 81.0003 17.0897 82.0114L18.9629 87.814C20.6987 93.191 25.7088 96.8311 31.359 96.8203L37.4565 96.8087C38.5189 96.8067 39.5543 97.1431 40.4126 97.7692L45.3388 101.363C49.9035 104.692 56.0965 104.692 60.6612 101.363L65.5874 97.7692C66.4457 97.1431 67.4811 96.8067 68.5435 96.8087L74.641 96.8203C80.2912 96.8311 85.3013 93.191 87.0371 87.814L88.9103 82.0114L85.1037 80.7825L88.9103 82.0114C89.2367 81.0003 89.8766 80.1196 90.7373 79.4967L95.6771 75.9221C100.254 72.6097 102.168 66.7199 100.412 61.3496L98.5167 55.5541C98.1865 54.5444 98.1865 53.4556 98.5167 52.4459L100.412 46.6504C102.168 41.2801 100.255 35.3903 95.6771 32.0779L90.7373 28.5033C89.8766 27.8804 89.2367 26.9997 88.9103 25.9886L85.1037 27.2175L88.9103 25.9886L87.0371 20.186C85.3013 14.809 80.2912 11.1689 74.641 11.1797L68.5435 11.1913C67.4811 11.1933 66.4457 10.8569 65.5874 10.2308L60.6612 6.63739L58.3269 9.83752L60.6612 6.63739C56.0965 3.30759 49.9036 3.30759 45.3388 6.63738L40.4126 10.2308Z" fill="#BF2D2D" stroke="#7F2121" stroke-width="8" />
                                <path d="M50.4955 58.45L46.9955 54.45L51.0955 50.65C52.0955 49.6833 52.8955 48.75 53.4955 47.85C54.0955 46.9167 54.3955 45.9333 54.3955 44.9C54.3955 44.1 54.0955 43.45 53.4955 42.95C52.9288 42.45 51.9122 42.2 50.4455 42.2L44.0455 44.35L41.6455 37.65C42.5788 36.8833 43.9288 36.1 45.6955 35.3C47.4955 34.5 49.6622 34.1 52.1955 34.1C56.0955 34.1 58.9288 34.9167 60.6955 36.55C62.4622 38.1833 63.3455 40.2833 63.3455 42.85C63.3455 45.1833 62.7455 47.2667 61.5455 49.1C60.3788 50.9 59.0288 52.35 57.4955 53.45L50.4955 58.45ZM50.4955 71.5L44.9955 66L50.4955 60.5L55.9955 66L50.4955 71.5Z" fill="#F3EED5" />
                            </svg>

                        </div>
                    <FaArrowRight className={`side-arrows ${disableRightArrow}`} onClick={() => moveImage(1)} />
                    <div className={isSnapshot} style={{display: 'flex', position: 'absolute', justifySelf: 'end', alignSelf: 'end', margin: '3%', width: 'auto', cursor: 'pointer'}}>
                        <p style={{alignSelf: 'center', color: 'black', fontWeight: 'bold', fontSize: '1.5em', margin: 'auto 1%', whiteSpace: 'nowrap'}}>Take Snapshot</p>
                        <FaCamera style={snapshotSource ? {color: 'var(--purple)'} : {color: '2e2e2e'}} size={'4em'} onClick={(e)=>SnapSource(e.target)}/>
                    </div>
                    <div className={isTeachMore} style={{display: 'flex', position: 'absolute', justifySelf: 'end', alignSelf: 'end', margin: '3%', width: 'auto', cursor: 'pointer'}}>
                        <p style={{alignSelf: 'center', color: 'black', fontWeight: 'bold', fontSize: '1.5em', margin: 'auto 1%', whiteSpace: 'nowrap'}}>Add Source</p>
                        <FaCamera style={{color: '2e2e2e'}} size={'4em'} onClick={(e)=>AddTeachMore(e.target)}/>
                    </div>
                </section>
                <section className={`bannerBackground ${objectivePopup}`} style={{zIndex: '2'}}> 
                        <section className={`bannerPopup`}>
                            <div className='header'>Phase Objective</div>
                            <div className='content' style={{whiteSpace: 'pre-line'}}>{objective}</div>
                            <div style={{display: 'flex'}}>
                                <button className='purple' style={{width: '50%', margin: 'auto', justifySelf: 'center'}} onClick={()=>setObjectivePopup('hidden')}>Close</button>
                            </div>
                        </section>
                </section>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Sources);
export { connection as Sources };