//--------------------------------------------------
//Phase Component
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const Phase = ({ number, isHidden, content }) => {

    ///THIS IS FOR CHANGING SOURCE TYPE

    const source_types = (value) => {
        return <select onChange={(e) => changeSourceType(e.target.parentElement, e.target.value)}><option selected={value == 'Image' ? true : false}>Image</option><option selected={value == 'Video' ? true : false}>Video</option><option selected={value == 'Text' ? true : false}>Text</option><option selected={value == 'Adaptation' ? true : false}>Adaptation</option></select>
    }

    const changeSourceType = (parent, value) => {
        let temp = parent.children[0];
        let temp2;
        if (value === 'Text' || value == 'Adaptation') {
            temp2 = document.createElement('textarea');
            temp2.placeholder = "Source Text";
        }
        else if (value === 'Video') {
            temp2 = document.createElement('input');
            temp2.placeholder = "Video URL";
            temp2.type = 'URL'
        }
        else {
            temp2 = document.createElement('input');
            temp2.type = 'file';
        }
        let temp3 = parent.children[2];
        parent.innerHTML = ''
        parent.appendChild(temp);
        parent.appendChild(temp2);
        parent.appendChild(temp3);
    }

    ///////////////////

    const [hidden, setHidden] = useState(isHidden)
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');
    const [sources, setSources] = useState([<div key={number + 'source' + 0} style={{margin: '0% 1% 0% 0%', display: 'inline-grid'}}>{source_types()}<input type='file'></input><input type='text' placeholder='Citation...' autoComplete='off'></input></div>])
    const [background, setBackground] = useState(null);


    useEffect(() => {
        setHidden(isHidden ? 'hidden' : '')

        if (content) {
            setName(content.name);
            setMessage(content.message);
            setType(content.type);
            let temparray = [];
            for (const x in content.sources) {
                const source = content.sources[x];
                switch(source.type) {
                    case 'Adaptation':
                    case 'Text':
                        temparray.push(<div key={number + 'source' + (temparray.length - 1)} style={{margin: '0% 1% 0% 0%', display: 'inline-grid'}}>{source_types(source.type)}<textarea defaultValue={source.file}></textarea><input type='text' placeholder={source.citation}  autoComplete='off'></input></div>);
                        break;
                    case 'Video':
                        temparray.push(<div key={number + 'source' + (temparray.length - 1)} style={{margin: '0% 1% 0% 0%', display: 'inline-grid'}}>{source_types(source.type)}<input type='URL' defaultValue={source.file}></input><input type='text' placeholder={source.citation}  autoComplete='off'></input></div>);
                        break;
                    default:
                        temparray.push(<div key={number + 'source' + (temparray.length - 1)} style={{margin: '0% 1% 0% 0%', display: 'inline-grid'}}>{source_types(source.type)}<input type='file'></input><input type='text' placeholder={source.citation}  autoComplete='off'></input><img style={{width: '50px'}} src={userActions.GetFile(source.file)} filename={source.file}></img></div>);
                        break;
                }
            }
            setSources(temparray);
        }
        // console.log(content)
    },[isHidden, content]);



    const AddSource = (image, citation) => {
        console.log('add')
        let temp = sources.slice();
        temp.push(<div key={number + 'source' + (temp.length - 1)} style={{margin: '0% 1% 0% 0%', display: 'inline-grid'}}>{source_types()}<input type='file' ></input><input type='text' placeholder='Citation...' defaultValue={citation} autoComplete='off'></input></div>);
        setSources(temp);
    }

    const DeleteSource = () => {
        console.log('delete')
        let temp = sources.slice();
        temp.pop();
        setSources(temp);
    }

    return (
        <Fragment>

            <div className={`${hidden}`} style={{ margin: 'auto', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <label className='font-bold font-lg grid-col-2'>Phase {number}</label>
                <div>
                    <label className='font-bold' style={{ fontSize: '1.5em' }} >Phase Name</label>
                    <input type='text' placeholder='Phase Name' className={'phase_name'} style={{ width: '90%', height: '75px', fontSize: '1.5em', padding: '0% 2%' }} value={name} onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div>
                    <label className='font-bold' style={{ fontSize: '1.5em' }} >Phase Background Image</label>
                    <input type='file' placeholder='Phase Background' className={'phase_background'} style={{ width: '90%', height: '75px', fontSize: '1.5em', padding: '.75% 2%' }}></input>
                    {background ? <img src={userActions.GetFile(background)} imagename={background}></img> : ''}
                </div>
                <div className='grid-col-2'>
                    <label className='font-bold' style={{ fontSize: '1.5em' }} >Phase Message</label>
                    <textarea type='text' placeholder='Phase Message' className={'phase_message'} style={{ width: '95%', height: '75px', borderRadius: '0.5em', fontSize: '1.5em', padding: '.75% 2%' }} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>
                <div className='grid-col-2'>
                    <label className='font-bold' style={{ fontSize: '1.5em' }}>Phase Type</label>
                    <br></br>
                    <select className='phase_type' style={{ width: '95%', height: '75px', fontSize: '1.5em', padding: '0% 2%' }} value={type} onChange={(e) => setType(e.target.value)}>
                        <option data='read'>Reading</option>

                        {/* Three Type Findings */}
                        {/* Allow up to three entries each, 1 gem each */}
                        {/* DONE */}
                        <option data='three_type'>Fact, Opinion, Unknown</option>
                        <option data='three_type'>Notice, Inference, Connection</option>
                        <option data='three_type'>Similarity, Difference, Question</option>
                        <option data='three_type'>See, Think, Wonder</option>

                        {/* Two Type Findings */}
                        {/* Allow up to three entries each, 1 gem each */}
                        {/* DONE */}
                        <option data='two_type'>Notice and Wonder</option> 
                        <option data='two_type'>Cause and Effect</option>
                        <option data='two_type'>Problem and Solution</option>
                        <option data='two_type'>Summary and Connection</option>
                        <option data='two_type'>Ordinary and Extraordinary</option>
                        <option data='two_type'>Conclusion and Question</option> 
                        <option data='two_type'>Similarity and Difference</option>
                        <option data='two_type'>Fact and Understanding</option>
                        <option data='two_type'>Benefit and Question</option>
                        <option data='two_type'>Problem and Question</option>
                        <option data='two_type'>Information and Question</option>

                        {/* Choose a notable quote, phrase, fact from one source */}
                        {/* Write about why you chose from source using one sentence */}
                        {/* Must do 1 for 8 gems, can do two for 16 */}
                        {/* DONE */}
                        <option data='phrase'>Notable Quote, Phrase, Fact</option>

                        {/* Static Questions */}
                        {/* Answer the question that is stated in text */}
                        {/* DONE */}
                        <option data='questions'>Answer Questions</option>

                        {/* User enters up to 5 events per date, at least 1. Then answer a question for each date */}
                        {/* ???????I do not see any question listed  */}
                        <option data='events'>Events</option>


                        {/* Enter details for each source 2 gems each, max 6 details per source */}
                        {/* 2nd column user names an individual or group 2 gems each (label: Perspective) */}
                        {/* DONE */}
                        <option data='details'>Details</option>


                        {/* Word is 1 gem, Sentence is 4 gems, u to 30 */}
                        {/* DONE */}
                        <option data='traits'>Traits</option>


                        {/* ?????? What is this entry? Idaho Women Phase 3 */}
                        <option data='word_bank'>Word Bank</option>
                        
                        {/* At least 3 sentences, 2 gems each sentence, max 24 gems */}
                        {/* DONE */}
                        <option data='story'>Fictional Story</option>
                        <option data='letter'>Letter</option>

                        {/*  Pick a source and give a reason and what they will learn from each (5 gems) (15 gem max)  */}
                        {/* ??????How do you want the user to select and source, and how should it be represented in the travelogue */}
                        <option data='teach_me_more'>Teach me More</option>
                       

                       {/* MIGHT NOT EXIST ANYMORE */}
                        {/* Choose X amount of Phrases in text by typing into travelogue. Write about why you chose these phrases */}
                        {/* 4 gems for phrases, 8 for responses */}
                        <option data='pick_phrases'>Pick Phrases</option>

                        {/* Order images in timeline. Should enter images into form correctly. Front-end mixes them up */}
                        {/* Two gems for each correctly placed image */}
                        {/* ???????Should user be told when an image is correct or only once all are correct? */}
                        {/* ALMOST DONE */}
                        <option data='ordering'>Ordering</option>


                        {/* Match two sources together */}
                        {/* DONE */}
                        <option data='matching'>Matching</option>


                        {/* Choose 10 - 20 words from quotes and put them into a column. Make a poem with up to 10 additional words. */}
                        {/* 3 gems per word, 3 gems for poem title, 10 gems for poem. 25 max */}
                        {/* DONE */}
                        <option data='poem'>Poem</option>

                        {/* 1 gem for snapshot, 3 gems for each response to 3 questions. */}
                        {/* Static Questions */}
                        <option data='snapshot'>Snapshot</option>

                        {/* Unity Phases */}
                        <option data='unity'>Preliminary Findings</option>
                        <option data='unity'>Creation</option>
                        <option data='unity'>Interpretation</option>
                        <option data='unity'>Reflection</option>

                        
                        {/* Phases like Civil Rights Phase 3 asks for an entry, but no where does it explain the type of entry besides it being a sentence */}
                        {/* Phases like Sovereignty Phase 4a should be matching. The visits will be very confusing with all the sub menus. Maybe we start with matching, then the next phases will be about each tribe */}
                        
                        {/* Many of these are the exact same with different type of resposne. I.e: Wants X sentences per source. Map it so that those will just use sentence count instead of unique conditions */}
                    </select>
                </div>
                <div className='grid-col-2'>
                    <label className='font-bold grid-col-2' style={{ fontSize: '1.5em' }}>Sources</label>
                    <div id={'source_container' + number} className={'phase_sources'} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {sources}
                    </div>
                    <button type='button' style={{ backgroundColor: 'green', color: 'white' }} onClick={() => AddSource('', '')}>Add another source</button>
                    <button type='button' style={{ backgroundColor: 'red', color: 'white' }} onClick={() => DeleteSource()}>Delete last source</button>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Phase);
export { connection as Phase };