import { useState, useEffect } from "react"
import { useIdleTimer } from "react-idle-timer"
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = (idleTime, onIdle) => {
    const [isIdle, setIsIdle] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsIdle(true);
        onIdle();
      }, idleTime);
  
      return () => {
        clearTimeout(timer);
      };
    }, [idleTime, onIdle]);
  
    return isIdle;
  };

  export {useIdleTimeout as useIdleTimeout};