//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";
import { DashboardNav } from "../DashboardComponents/DashboardNav";
import { StudentList } from "../DashboardComponents/StudentList";
import { ConfirmationPopup } from "../DashboardComponents/ConfirmationPopup";

const Classroom = ({ }) => {
    const [studentToRemove, setStudentToRemove] = useState('');
    const [showBanner, setBanner] = useState('hidden');
    const [studentRemoveName, setStudentRemoveName] = useState('');
    const id = window.location.href.split('/')[window.location.href.split('/').length - 1];
    const token = localStorage.getItem('token');

    const [updateChild, setUpdateChild] = useState(Date.now());

    const TryRemoveStudent = (studentID, name) => {
        setStudentToRemove(studentID);
        setStudentRemoveName(name);
        setBanner('');
    }

    const ConfirmRemoveStudent = (confirmation) => {
        if (confirmation) {
            userActions.RemoveStudent(studentToRemove, id, token, () => {
                setUpdateChild(Date.now())
            });
        }
        setBanner('hidden');
        setStudentToRemove(null);
        setStudentRemoveName('');
    }
    
    

    return (
        <Fragment>
            <div className='main-container page max-height-screen'>

                <DashboardNav />
                <StudentList Popup={TryRemoveStudent} key={updateChild}/>
            </div>
            <ConfirmationPopup confirmation={ConfirmRemoveStudent} display={showBanner} student={studentRemoveName}/>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Classroom);
export { connection as Classroom };