//--------------------------------------------------
//Rendered Component for verifying email
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const VerifyEmail = ({session, VerifyEmail, SendVerificationEmail}) => {
    const params = new URLSearchParams(new URL(window.location.href).search);
    const verificationToken = params.get("verificationToken");

    useEffect(() => {
        if(verificationToken && session.token) VerifyEmail(session.token, verificationToken);
    }, []); 

    console.log(verificationToken)

    return (
        <Fragment>
            {verificationToken ?
            <div className=''>
                {verificationToken && session.token ? <h1>Verifying Email...</h1> : <h1>Invalid token or not logged in.</h1>}
            </div>
            :
            <button className='change-button' onClick={() => SendVerificationEmail(session.token)}>Send Verification Email</button>
            }
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    VerifyEmail: userActions.VerifyEmail,
    SendVerificationEmail: userActions.SendVerificationEmail,
};

const connection = connect(mapState, actionCreators)(VerifyEmail);
export { connection as VerifyEmail };